import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    menus: [],
    enMenus: [],
    jpMenus: [],
    koMenus: [],
  },
  getters: {},
  mutations: {
    setMenus: (state, menus) => {
      state.menus = menus
    },
    setEnMenus: (state, menus) => {
      state.enMenus = menus
    },
    setJpMenus: (state, menus) => {
      state.jpMenus = menus
    },
    setKoMenus: (state, menus) => {
      state.koMenus = menus
    },
  },
  actions: {},
  modules: {},
})
