import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/Home.vue'),
    meta: {
      title: '官网 阜宁县马家荡旅游景区',
    },
  },
  {
    path: '/Content/:id',
    name: 'Content',
    component: () => import('../views/Content.vue'),
    meta: {
      title: '官网 阜宁县马家荡旅游景区',
    },
  },
  {
    path: '/Content-EN/:id',
    name: 'Content-EN',
    component: () => import('../views/Content-EN.vue'),
    meta: {
      title: '官网 阜宁县马家荡旅游景区',
    },
  },
  {
    path: '/Content-JP/:id',
    name: 'Content-JP',
    component: () => import('../views/Content-JP.vue'),
    meta: {
      title: '官网 阜宁县马家荡旅游景区',
    },
  },
  {
    path: '/Content-KO/:id',
    name: 'Content-KO',
    component: () => import('../views/Content-KO.vue'),
    meta: {
      title: '官网 阜宁县马家荡旅游景区',
    },
  },
  {
    path: '/mobile',
    name: 'Mobile',
    component: () => import('../views/Mobile.vue'),
    meta: {
      title: '官网 阜宁县马家荡旅游景区',
    },
  },
  {
    path: '/mobileIndex',
    name: 'MobileIndex',
    component: () => import('../views/MobileIndex.vue'),
    meta: {
      title: '官网 阜宁县马家荡旅游景区',
    },
  },
  {
    path: '/mobileIndexEnglish',
    name: 'MobileIndexEN',
    component: () => import('../views/MobileIndexEN.vue'),
    meta: {
      title: '官网 阜宁县马家荡旅游景区',
    },
  },
  {
    path: '/mobileIndexJapanese',
    name: 'MobileIndexJP',
    component: () => import('../views/MobileIndexJP.vue'),
    meta: {
      title: '官网 阜宁县马家荡旅游景区',
    },
  },
  {
    path: '/mobileIndexKorean',
    name: 'MobileIndexKR',
    component: () => import('../views/MobileIndexKO.vue'),
    meta: {
      title: '官网 阜宁县马家荡旅游景区',
    },
  },
]

const router = new VueRouter({
  routes,
})

export default router
