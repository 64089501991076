import router from './router'

router.beforeEach((to, from, next) => {
  if (to.path == '/') {
    if (document.body.clientWidth < 445) {
      // 更换网页标题
      document.title = to.meta.title
      next('/mobile')
    } else {
      // 更换网页标题
      document.title = to.meta.title
      next()
    }
  } else {
    // 更换网页标题
    document.title = to.meta.title
    next()
  }
})